<template>
  <div class="flex flex-col justify-center h-full">
    <div
      class="flex justify-center items-center h-[68px] sticky top-0 z-10 md:static md:z-0 border-b w-full"
      :style="{
        backgroundColor: $attrs['header-bg-color'] ? $attrs['header-bg-color'] : 'var(--background-color)',
        borderColor: $attrs['header-bg-color'] ? $attrs['header-bg-color'] : null,
      }"
    >
      <div
        v-if="$attrs.logo || hasCustomLogo"
        :style="{
          backgroundImage: `url(${$attrs.logo || whitelabel.get('logoUrl')}`,
        }"
        class="bg-cover bg-center mx-auto h-16 whitelabel-logo"
      />
      <PulseIconLogo v-else name="logo" />
    </div>
    <div
      class="flex-1 flex md:items-center justify-center px-2 md:px-12"
      :class="{ 'max-h-screen h-20': !!$attrs.print }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useWhiteLabelStore } from '~/store';

const whitelabel = useWhiteLabelStore();

const { hasCustomLogo } = storeToRefs(whitelabel);

useHead({
  link: [
    {
      href: whitelabel.get('faviconUrl'),
      rel: 'icon',
    },
  ],
  title: whitelabel.get('pageTitle') || 'OrderProtection.com - Claims',
});
</script>

<style>
.whitelabel-logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: var(--logo-width-mobile, 115px);
}

@media screen and (min-width: 768px) {
  .whitelabel-logo {
    width: var(--logo-width-desktop, 115px);
  }
}
</style>
